import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  Self,
  Input,
  DoCheck,
  Optional,
  Component,
  OnDestroy,
  ElementRef,
  HostBinding,
} from '@angular/core';

import { Subject } from 'rxjs';

import { Board, BoardInfo, BoardInfoService, } from '@core/api';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-select-board-section[board]',
  templateUrl: './select-board-section.component.html',
  styleUrls: ['./select-board-section.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectBoardSectionComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SelectBoardSectionComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<BoardInfo | null> {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-board-section';
  describedBy = '';
  stateChanges = new Subject<void>();

  sections: BoardInfo[] = [];
  selectCtrl = new FormControl();

  @Input() tabIndex;

  @HostBinding('id') id = `select-board-section-${SelectBoardSectionComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @Input() set board(board: Board) {
    this.value = null;
    this._board = board;

    if (board) {
      this.loading = true;
      this.boardInfoService
        .search({ page: 1, pageSize: 500, filter: { boardId: board.boardId } })
        .subscribe((response) => {
          this.sections = response?.data?.results.sort((a, b) => a.orderBy - b.orderBy) ?? [];

          // Select first section by default
          if (this.sections.length > 0) {
            this.value = this.sections[0];
          }
        })
        .add(() => this.loading = false);
    }
  }
  get board(): Board {
    return this._board;
  }
  private _board: Board;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): BoardInfo { return this.selectCtrl.value; }
  set value(value: BoardInfo) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  get empty() { return !this.value; }

  get showOptions(): boolean {
    return !this.showNotFound && !this.showSelectBoard;
  }

  get showNotFound(): boolean {
    return !this.loading && !this.showSelectBoard && this.sections.length === 0;
  }

  get showSelectBoard(): boolean {
    return !this.loading && !this.board;
  }

  get shouldLabelFloat() {
    return this.focused || this.showNotFound || this.showSelectBoard || !this.empty;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private boardInfoService: BoardInfoService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    this._focusMonitor.monitor(this._elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  writeValue = (value: BoardInfo) => {
    this.value = value;

    if (value) {
      const hasValue = this.sections?.find(board => board.boardInfoId === value.boardInfoId) ?? false;

      if (!hasValue) {
        this.sections.push(value);
      }
    }
  }

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: BoardInfo, two: BoardInfo) => one && two && one.boardInfoId === two.boardInfoId;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();
}
