import { AsYouType, isSupportedCountry } from 'libphonenumber-js';

import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomerPhoneType, Email, Phone } from '@core/api';

export class AppValidators {

  static EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  static isInputGroupFilled(group: any[]): boolean {

    if (!group || !Array.isArray(group) || group.length === 0) {
      return false;
    }

    return group
      .map(input => {
        const values = Object.values(input);

        const isPhone = Object.keys(input).includes('internalPhone');
        if (isPhone) {
          const internalControl = (CustomerPhoneType.OFFICE === input.phoneTypeId && input.internalPhone) ? 0 : 1;
          return (values.length === values.filter(value => !!value).length + internalControl) &&
            (isSupportedCountry(input.country?.sortName) ? checkPhoneNumber(input.phoneNumber) : input.phoneNumber > 0);
        }

        return values.length === values.filter(value => !!value).length;
      })
      .every(input => true === input);
  }

  static fullUrl(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const urlRegex = /^(http[s]?:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,63})+)(\/(.)*)?(\?(.)*)?$/;
      const isValid = urlRegex.test(control.value);

      if (isValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static url(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\/?$/;
      const isValid = urlRegex.test(control.value);

      if (isValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static httpsUrl(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const type = control.value?.slice(0, 5);
      if (type === 'https') {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static domainOrUrl(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const urlRegex = /^((http[s]?:\/\/)?(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,63})+)(\/(.)*)?(\?(.)*)?$/;
      const isValid = urlRegex.test(control.value);

      if (isValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      if (checkPhoneNumber(control.value)) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static phoneSimple(control: AbstractControl): ValidationErrors | null {
    if (!control?.value) {
      return null;
    }

    if (/^[0-9\(\)\-\+ ]+$/.test(control.value)) {
      return null;
    }

    return { required: true };
  }

  static emails(control: AbstractControl): ValidationErrors | null {
    if (control.value?.length > 0) {
      const isEmailsValid = control.value.every((email: Email) => checkEmail(email.mailName));
      if (isEmailsValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static emailsWithType(control: AbstractControl): ValidationErrors | null {
    if (control.value?.length > 0) {
      const isEmailsValid = control.value.every((email: Email) => checkEmail(email.mailName) && email.emailTypeId);
      if (isEmailsValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static phones(control: AbstractControl): ValidationErrors | null {

    if (control.value?.filter((phone: Phone) => phone?.phoneNumber).length > 0) {
      const isPhonesValid = control.value.filter((phone: Phone) => phone?.phoneNumber && phone?.phoneNumber !== phone?.prefix).every((phone: Phone) =>
        checkPhoneNumber(phone.phoneNumber.startsWith('+') ? phone.phoneNumber : '+' + phone.phoneNumber) && phone.phoneTypeId);
      if (isPhonesValid) {
        return null;
      }

      return { required: true };
    }

    return null;
  }

  static phonesWithNullCheck(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || control.value.length === 0 || control.value.filter(phone => phone.phoneNumber).length === 0) {
      return { required: true };
    }

    return AppValidators.phones(control);
  }

  static fullName(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const words = control.value.split(' ');

      // Allow 2 word at least
      if (words.length >= 2) {
        return null;
      }
    }

    return { required: true };
  }

  static minSelectedCheckboxes(min: number): ValidatorFn {
    return (formArray: UntypedFormArray) => {
      const selected = formArray.controls.filter((control) => !!control.value).length;

      if (selected >= min) {
        return null;
      }

      return { required: true };
    };
  }

  static inputGroupRequired(control: AbstractControl): ValidationErrors | null {

    if (control.value && Array.isArray(control.value) && control.value.length > 0 && AppValidators.isInputGroupFilled(control.value)) {
      return null;
    }

    return { required: true };
  }

  static inputGroupRequireAtLeast(field: string): ValidatorFn {
    let pair: AbstractControl;

    const updatePairValidity = () => {
      setTimeout(() => {
        pair.updateValueAndValidity();
      });
    };

    return (control: AbstractControl) => {
      const lookupPair = control.root?.get(field);
      const isControlFilled = AppValidators.isInputGroupFilled(control.value);

      // Update local pair variable when exists in control tree and store to access each call
      if (lookupPair) {
        pair = lookupPair;
      }

      if (pair) {
        // Require when both pair and control are empty
        if (!pair.value && !control.value) {
          return { required: true };
        }

        // Require when both pair and control arrays are empty
        if (Array.isArray(pair.value) && Array.isArray(control.value) && pair.value.length === 0 && control.value.length === 0) {
          return { required: true };
        }

        // Require when both pair and control are not filled
        if (!isControlFilled && !AppValidators.isInputGroupFilled(pair.value)) {
          if (pair.valid) {
            updatePairValidity();
          }

          return { required: true };
        }

        if (field === 'phones') {
          if (control.value?.filter((values) => values.mailName).filter(value => value.emailTypeId === null).length > 0) {
            return { required: true };
          }
        }

        if (field === 'emails') {
          if (control.value?.filter(value => value.phoneNumber).filter(value => value.phoneTypeId === null).length > 0) {
            return { required: true };
          }
        }
        // Mark valid when pair is valid and control is empty
        if (pair.valid && !isControlFilled) {
          return null;
        }

        // Update pair validity for sync
        if (isControlFilled && !pair.valid || !isControlFilled && pair.valid) {
          updatePairValidity();
        }
      }

      return isControlFilled ? null : { required: true };
    };
  }

  static minDecimal(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const value = +control.value.toString().replace(',', '.');

        if (value < min) {
          return { min: true };
        }
      }

      return null;
    };
  }

  static maxDecimal(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const value = +control.value.toString().replace(',', '.');

        if (value > max) {
          return { max: true };
        }
      }

      return null;
    };
  }
}

function checkPhoneNumber(value: string | Phone): boolean {
  // Create as you type input
  const inputType = new AsYouType();

  if (value === null) {
    return true;
  }

  // Update value to input
  if (typeof value === 'object') {
    inputType.input(value.phoneNumber);
  } else {
    inputType.input(value);
  }

  // Get number from input
  const phoneNumber = inputType.getNumber();
  let basicNumber;

  if (typeof value === 'object') {
    basicNumber = (value as Phone)?.phoneNumber?.replace(`+${phoneNumber?.countryCallingCode}`, '').replace(/ /g, '');
  } else {
    basicNumber = value.replace(`+${phoneNumber?.countryCallingCode}`, '').replace(/ /g, '');
  }

  // Validate with libphonenumber-js
  if (phoneNumber && phoneNumber.isPossible() && basicNumber.split('')[0] !== '0') {
    return true;
  }

  return false;
}

function checkEmail(email: string): boolean {
  return AppValidators.EMAIL_REGEXP.test(email);
}
