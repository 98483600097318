import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, DoCheck, ElementRef, EventEmitter, HostBinding, Input,
  OnDestroy, OnInit, Optional, Output, Self, ViewChild,
  inject
} from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ApiService, SpecialDayType } from '@core/api';

import { SpecialDay } from './input-special-day.component.model';
import { SharedModule } from '@shared/modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icon } from '@shared/enums';
import { FormChangedDirective } from '@shared/directives';

@Component({
  selector: 'net-input-special-day',
  templateUrl: './input-special-day.component.html',
  styleUrls: ['./input-special-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    FormChangedDirective
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputSpecialDayComponent
    }
  ]
})
export class InputSpecialDayComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<SpecialDay[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-special-day';
  describedBy = '';
  stateChanges = new Subject<void>();

  days: SpecialDay[];
  types: SpecialDayType[];

  form: UntypedFormGroup;

  icAdd = Icon.IC_TWOTONE_ADD;
  icMinus = Icon.IC_TWOTONE_MINUS;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SpecialDay[] | null { return this._value; }
  set value(days: SpecialDay[] | null) {
    this._value = days;
    this.onChange(days);
    this.stateChanges.next();
  }
  private _value: SpecialDay[];

  @Output() invalidForm = new EventEmitter();

  @HostBinding('id') id = `input-special-day-${InputSpecialDayComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  @ViewChild('select', { static: true }) select: MatSelect;

  get shouldLabelFloat() { return true; }

  get empty() { return !this.value || this.value.length === 0; }

  private readonly destroyRef = inject(DestroyRef);
  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    const firstForm = this.createDayForm();
    this.listenFormArray(firstForm, 'description', 'date');
    this.listenFormArray(firstForm, 'date', 'description');

    this.form = new UntypedFormGroup({
      days: new UntypedFormArray([firstForm])
    }, { updateOn: 'change' });

    // Fetch special day types
    // this.api.specialDayType.list().subscribe(response => {
    //   this.types = response.data;
    //   this.changeDetectorRef.detectChanges();
    // });
  }

  private isEmpty(item: SpecialDay) {
    return !item.date || !item.description;
  }

  ngOnInit() {
    this.form.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.form.invalid) {
        this.invalidForm.emit(true);
        return;
      }

      const days: SpecialDay[] = this.getDaysForm().value.filter(item => false === this.isEmpty(item));
      const value = days.length > 0 ? days : null;
      this.invalidForm.emit(false);
      this.value = value;
      this.changeDetectorRef.detectChanges();
    });

    this.changeDetectorRef.detectChanges();
  }

  getDaysForm(): UntypedFormArray {
    return this.form.get('days') as UntypedFormArray;
  }

  createDayForm(day?: SpecialDay): UntypedFormGroup {
    return this.formBuilder.group({
      date: [day ? day.date : null],
      description: [day ? day.description : null],
      valid: [true],
      contactSpecialDayId: [day ? day.contactSpecialDayId : null]
    });
  }

  add(day?: SpecialDay) {
    const newForm = this.createDayForm(day);

    this.listenFormArray(newForm, 'description', 'date');
    this.listenFormArray(newForm, 'date', 'description');

    this.getDaysForm().push(newForm);
    this.changeDetectorRef.detectChanges();
  }

  listenFormArray(form: UntypedFormGroup, formControlName: string, updateFormControlName: string) {
    const formControl = form.get(formControlName);
    const updateFormControl = form.get(updateFormControlName);
    formControl.valueChanges.pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef)).subscribe(item => {
      if (item) {
        updateFormControl.setValidators(Validators.required);
        updateFormControl.markAsTouched();
      } else {
        updateFormControl.setValidators([]);
        updateFormControl.markAsUntouched();
        if (updateFormControl.value) {
          formControl.setValidators(Validators.required);
          formControl.updateValueAndValidity({ emitEvent: false });
        }
        updateFormControl.markAsPristine();
      }
      updateFormControl.updateValueAndValidity({ emitEvent: false });
    });
  }

  remove(index: number) {
    const form = this.getDaysForm();

    if (form.length > 0) {
      form.removeAt(index);
    }

    if (form.length === 0) {
      this.getDaysForm().push(this.createDayForm());
    }

    this.changeDetectorRef.detectChanges();
  }

  writeValue(days: SpecialDay[]) {
    if (days?.length > 0) {
      this.days = days;
      this.value = days;
      this.getDaysForm().clear();

      days.forEach(day => {
        const firstForm = this.createDayForm();
        firstForm.patchValue({
          date: day.date,
          description: day.description
        });
        this.listenFormArray(firstForm, 'description', 'date');
        this.listenFormArray(firstForm, 'date', 'description');
      });

      days.forEach(day => this.add(day));
      this.changeDetectorRef.detectChanges();
    }
  }

  compareWithType(one: SpecialDayType, two: SpecialDayType) {
    return one && two && one.specialDayTypeId === two.specialDayTypeId;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.form.invalid && this.form.touched;
      this.stateChanges.next();
    }
  }
}
