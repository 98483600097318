import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@env/environment';
import * as signalR from '@microsoft/signalr';
import { CustomConsole } from '@shared/utils';
import { BehaviorSubject, catchError, ReplaySubject, throwError } from 'rxjs';
import { SmartQueryThreadActionType } from './smart-query.enum';
import { Response } from '../api.model';
import { SmartQueryThread, SmartQueryThreadActionModel } from './smart-query.model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class SmartQuerySignalRService {

    private hubConnection: signalR.HubConnection;
    private threads: SmartQueryThread[] = [];
    private selectedThread: SmartQueryThread;
    private request: any;

    private console = new CustomConsole();

    public loadingSubject = new ReplaySubject<boolean>();
    public loading$ = this.loadingSubject.asObservable();

    public connectionSubject = new BehaviorSubject<boolean>(false);
    public connection$ = this.connectionSubject.asObservable();

    private threadsSubject = new ReplaySubject<SmartQueryThread[]>();
    public threads$ = this.threadsSubject.asObservable();

    private threadSubject = new BehaviorSubject<SmartQueryThread>(null);
    public thread$ = this.threadSubject.asObservable();

    private readonly authService = inject(AuthService);
    private readonly router: Router = inject(Router);
    private readonly route = inject(ActivatedRoute);

    public startConnection = () => {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.socketUrl}/aihub?x-client=${environment.socketTenant}`, {
                accessTokenFactory: async () => {
                    await this.getToken(); return AuthService.getToken();
                }, withCredentials: true, transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60;

        this.hubConnection.onclose((err) => {
            if (err) {
                if (err.stack.includes('WebSocket closed with status code: 1006 ()')) { setTimeout(() => { location.reload(); }, 1000); }
                setTimeout(() => {
                    this.hubConnection.start()
                        .then(() => this.console.info('Websocket Connection Established'))
                        .catch(error => this.console.error('SignalR Connection Error: ', error));
                }, 500);
            }
        });

        this.hubConnection.onreconnected(() => {
            if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
                this.listenArtificialIntelligence();
                this.getThreads();
            }
        });

        this.hubConnection
            .start()
            .then(() => {

                if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
                    this.listenArtificialIntelligence();
                    this.getThreads();
                    this.connectionSubject.next(true);
                    this.loadingSubject.next(false);
                }
            })
            .catch(err => this.console.info('Error while starting connection: ' + err));
    }

    public invokeThreadAction = (methodName: SmartQueryThreadActionType, request: any) => {
        this.request = request;
        this.loadingSubject.next(true);
        if (methodName === SmartQueryThreadActionType.NEW_QUESTION) {
            this.selectedThread = {
                ...this.selectedThread,
                responses: [...this.selectedThread.responses, {
                    ...request,
                    isLoading: true
                }]
            };
            this.threadSubject.next(this.selectedThread);
        }
        return this.hubConnection.invoke(methodName, request);
    }

    public setSelectedThread = (thread: SmartQueryThread, isCallHub: boolean = true, isNewThread: boolean = false) => {
        if (!thread) {
            this.threadSubject.next(null);
            this.router.navigate([`smart-query/new-thread`]);
            return;
        }
        if (isNewThread) {
            this.threadSubject.next(thread);
        } else {
            this.selectedThread = thread;
        }

        if (isCallHub) {
            this.invokeThreadAction(SmartQueryThreadActionType.GET_THREAD, thread.id);
        }

        this.router.navigate([`smart-query/${thread.id}`], { relativeTo: this.route });

    }

    private getThreads = () => {
        this.hubConnection.invoke(SmartQueryThreadActionType.GET_THREADS);
    }

    private listenArtificialIntelligence = () => {
        this.hubConnection.on(SmartQueryThreadActionType.ARTIFICAIL_INTELLIGENCE, (response: Response<SmartQueryThreadActionModel>) => {

            if (response) {
                this.loadingSubject.next(true);
                this.setSocketResponse(response.data);
                this.loadingSubject.next(false);
            }
            this.console.info('Artificial Intelligence: ', response);
        });
    }

    private setSocketResponse(action: SmartQueryThreadActionModel) {

        if (action?.threads && action.threads.length > 0) {
            this.threads = action.threads;
            this.threadsSubject.next(action.threads);
            return;
        }

        if (action?.thread) {
            this.selectedThread = {
                ...this.selectedThread,
                id: action.thread[0].threadId,
                responses: action.thread
            };
            this.threadSubject.next(this.selectedThread);
            return;
        }

        if (action?.updateThread) {
            const index = this.threads.findIndex(t => t.id === action.updateThread.id);
            this.threads[index] = action.updateThread;
            this.threadsSubject.next(this.threads);
            return;
        }

        if (action?.deleteThread) {
            this.threads = this.threads.filter(t => t.id !== this.request);
            this.threadsSubject.next(this.threads);
            if (this.selectedThread?.id === this.request) {
                this.threadSubject.next(null);
                this.router.navigate(['smart-query', 'new-thread']);
                return;
            }
            return;
        }

        if (action?.createThread) {

            const addedThread = {
                id: Number(action.createThread.id),
                summary: action.createThread.summary,
                responses: [
                    {
                        question: action.createThread.summary,
                        isLoading: true,
                    }
                ],
            } as SmartQueryThread;

            this.threads.unshift(addedThread);
            this.threadsSubject.next(this.threads);

            this.setSelectedThread(addedThread, false, true);
            return;
        }

        if (action?.newQuestion) {

            const thread = this.selectedThread;
            const index = thread.responses.findIndex(r => r.fakeResponseId === -1);

            thread.responses[index] = {
                ...action.newQuestion,
                isLoading: false,
                fakeResponseId: null
            };
            this.threadSubject.next(thread);
            return;
        }

        if (action?.newQuestion === null) {

            const thread = this.selectedThread;
            const index = thread.responses.findIndex(r => r.fakeResponseId === -1);

            thread.responses[index] = {
                ...action.newQuestion,
                question: action.newQuestion.summary,
                isLoading: false,
                isError: true,
                fakeResponseId: null
            };
            this.threadSubject.next(thread);
            return;
        }

    }

    public disconnect = () => {
        if (!this.hubConnection) {
            return;
        }

        this.hubConnection.stop();
        this.loadingSubject.next(false);
        this.connectionSubject.next(false);
        this.threadSubject.next(null);
    }

    private getToken(): Promise<void> {
        return new Promise((resolve) => {
            this.authService.refreshToken().pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 401:
                                this.authService.logout(true, true);
                                break;
                        }
                    }
                    return throwError(error);
                })
            ).subscribe(() => resolve());
        });
    }

}
