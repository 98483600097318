<mat-chip-grid #chipList #origin="matAutocompleteOrigin" matAutocompleteOrigin [disabled]="disabled">
  <mat-chip-row *ngFor="let item of value" [removable]="true" (removed)="onRemoveChip(item)">
    {{ item.tag }}
    <net-iconify-icon [icon]="icCancel" inline="true" matChipRemove></net-iconify-icon>
  </mat-chip-row>
  <input #input [tabIndex]="tabIndex" [formControl]="inputCtrl" [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin" [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" [placeholder]="placeholder"
    [readOnly]="loading" (matChipInputTokenEnd)="onAddToken($event)" (focus)="reset()" netFormChanged>
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [autoActiveFirstOption]="true">
  <ng-container *ngIf="this.hashtags">
    <mat-option *ngFor="let item of hashtags | orderBy: 'tag': false" [value]="item">{{ item.tag }} </mat-option>
  </ng-container>
</mat-autocomplete>
<mat-progress-spinner *ngIf="loading === true" diameter="16" mode="indeterminate"></mat-progress-spinner>