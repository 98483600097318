import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseResponse,
  Card,
  CardListUpdateRequest,
  CardParentCardUpdateRequest,
  CardRangeRequest,
  CardRequest,
  CardSearchRequest,
  PagedResponse,
  Response,
  UpdateCardAssignedUsersRequest
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http: HttpClient) { }

  insert(request: CardRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/Insert', request);
  }

  insertSub(request: CardRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/InsertSub', request);
  }

  insertRange(request: CardRangeRequest): Observable<Response<Card[]>> {
    return this.http.post<Response<Card[]>>(environment.apiUrl + '/api/Card/InsertRange', request);
  }

  delete(request: CardRequest | Card): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/Delete', request);
  }

  listUpdate(request: CardListUpdateRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/ListUpdate', request);
  }

  search(request: CardSearchRequest): Observable<PagedResponse<Card>> {
    return this.http.post<PagedResponse<Card>>(environment.apiUrl + '/api/Card/Search', request);
  }

  dependencySearch(request: CardSearchRequest): Observable<{ data: Card[] } & BaseResponse> {
    return this.http.post<{ data: Card[] } & BaseResponse>(environment.apiUrl + '/api/Card/DependencySearch', request);
  }

  get(request: { cardId: string; boardId: string }): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/Get', request);
  }

  update(request: CardRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/Update', request);
  }

  updateParentCard(request: CardParentCardUpdateRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/UpdateParentCard', request);
  }

  getCardChildCards(request: string): Observable<Response<Card>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/GetCardChildCards', JSON.stringify(request), { headers });
  }

  updateCardAssignedUsers(request: UpdateCardAssignedUsersRequest): Observable<Response<Card>> {
    return this.http.post<Response<Card>>(environment.apiUrl + '/api/Card/UpdateCardAssignedUsers', request);
  }
}
