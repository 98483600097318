import {
  ChangeDetectorRef, Component, DoCheck, ElementRef,
  HostBinding, Input, OnDestroy, Optional, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { Hashtag, HashtagService } from '@core/api';
import { InputHashtagType } from './input-hashtag.component.enum';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';
import { FormChangedDirective } from '@shared/directives';

@Component({
  selector: 'net-input-hashtag[type]',
  templateUrl: './input-hashtag.component.html',
  styleUrls: ['./input-hashtag.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    FormChangedDirective
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputHashtagComponent
    }
  ]
})
export class InputHashtagComponent implements OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Hashtag[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'input-hashtag';
  describedBy = '';
  stateChanges = new Subject<void>();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  hashtags: Hashtag[];
  inputCtrl = new UntypedFormControl();

  icCancel = Icon.IC_TWOTONE_CANCEL;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() type: InputHashtagType | number;
  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Hashtag[] | null { return this._value; }
  set value(value: Hashtag[] | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: Hashtag[] = [];

  @HostBinding('id') id = `input-hashtag-${InputHashtagComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private hashtagService: HashtagService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      filter(value => !!value && typeof value === 'string'),
      debounceTime(400),
      tap(() => {
        this.loading = true;
        this.hashtags = [];
      }),
      switchMap(value => this.hashtagService.search({
        filter: {
          hashtagType: this.type,
          tag: value
        },
        pageSize: 30
      })),
      map(response => response.data.results.filter(hashtag => {
        return !this.value.some(selected => selected.hashtagId === hashtag.hashtagId);
      })),
      tap(() => this.loading = false)
    ).subscribe(hashtags => {
      if (this.type === 0 as number) {
        this.hashtags = [];
        hashtags.forEach(h => {
          if (this.hashtags.filter(f => f.tag.replace(/\s/g, '') === h.tag.replace(/\s/g, '')).length === 0) {
            this.hashtags.push({
              hashtagId: h.hashtagId,
              hashtagType: h.hashtagType,
              tag: h.tag
            });
          }
        });
      } else {
        this.hashtags = hashtags;
      }
    });
  }

  reset() {
    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value.push(event.option.value);
    this.onChange(this.value);
    this.reset();
  }

  onAddToken(event: MatChipInputEvent) {
    if (this.loading || !event.value) {
      return;
    }

    const hashtag = {
      hashtagType: this.type,
      tag: event.value
    };

    if (false === this.value.some(selected => selected.tag === hashtag.tag)) {
      this.value.push(hashtag);
      this.onChange(this.value);
    }

    this.reset();
  }

  onRemoveChip(hashtag: Hashtag) {
    const index = this.value.indexOf(hashtag);

    if (index >= 0) {
      this.value.splice(index, 1);
      this.onChange(this.value);
    }

    this.reset();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Hashtag[]) {
    if (value instanceof Array) {
      this.value = value;
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
