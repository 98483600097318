import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, FormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { Address, AddressService, Customer } from '@core/api';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/modules';
import { Icon } from '@shared/enums';
import { AddressDialogData } from '../address-form-dialog/address-form-dialog.component.model';
import { AddressFormDialogComponent } from '../address-form-dialog/address-form-dialog.component';
import { FormChangedDirective } from '@shared/directives';

@Component({
  selector: 'net-input-customer-address[customer]',
  templateUrl: './input-customer-address.component.html',
  styleUrls: ['./input-customer-address.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputCustomerAddressComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    FormChangedDirective
  ]
})
export class InputCustomerAddressComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<Address | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-address';
  describedBy = '';
  stateChanges = new Subject<void>();

  icAdd = Icon.IC_TWOTONE_ADD;
  icEdit = Icon.IC_EDIT;

  addresses: Address[] = [];
  inputCtrl = new UntypedFormControl();

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() isDisabled = false;
  @Input() tabIndex;

  @Input() source: Observable<Address[]>;

  @Input() editable = false;
  @Output() update: EventEmitter<Address[]> = new EventEmitter<Address[]>();
  @ViewChild(FormChangedDirective) formChangedDirective!: FormChangedDirective;
  @HostBinding('id') id = `select-address-${InputCustomerAddressComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private addressService: AddressService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.loading = true;
        this.addresses = [];
        // this.value = null;
      }),
      switchMap(value => this.addressService.search({
        filter: {
          enabled: true,
          customerId: this.customer.customerId,
          addressName: value
        },
        pageSize: 20
      }, !!this.checkPermissions)),
      tap(() => this.loading = false)
    ).subscribe((response) => {
      this.addresses = response.data.results;
      cdr.detectChanges();
    });
  }

  private _reSelectFirst = true;

  @Input()
  get reSelectFirst(): boolean {
    return this._reSelectFirst;
  }

  set reSelectFirst(value: boolean) {
    this._reSelectFirst = coerceBooleanProperty(value);
    if (value) {
      this.selectFirst();
    }
  }

  private _customer;

  @Input()
  get customer() {
    return this._customer;
  }

  set customer(customer: Customer) {
    this._customer = customer;
    this.reset();

    if (customer && !this.source) {
      this.loading = true;
      this.inputCtrl.disable();
      this.initDefaultOptions().finally(() => {
        this.loading = false;
        if (!this.disabled) {
          this.inputCtrl.enable();
        }
        this.cdr.detectChanges();
      });
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  private _value: Address;

  @Input()
  get value(): Address | null {
    return this._value;
  }

  set value(value: Address | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  private _checkPermissions: boolean;

  @Input()
  set resetAddresses(value: boolean) {
    if (value) {
      this.initDefaultOptions();
    }
  }

  @Input()
  get checkPermissions(): boolean {
    return this._checkPermissions;
  }

  set checkPermissions(value: boolean) {
    this._checkPermissions = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  ngOnInit() {
    if (this.source) {
      this.source.subscribe(addresses => {
        this.addresses = addresses;
        this.selectFirst();
      });
    }
  }

  displayName = (address: Address) => {
    if (!address) {
      return '';
    }

    const translatedCity = this.translate.instant('City.' + address.city?.name);

    return `${address.name} - ${address.addressLine ? address.addressLine : ''} ${address.county ? address.county?.name : ''} ${address.city ? translatedCity : ''}`;
  }

  add(value?: Address) {
    let data: AddressDialogData = { customer: this.customer };

    if (value) {
      data = { customer: this.customer, address: value };
    }

    this.dialog.open(AddressFormDialogComponent, { autoFocus: false, data })
      .addPanelClass('cdk-overlay-full')
      .afterClosed()
      .subscribe((address: Address) => {
        if (address) {
          this.addressService.get(address.addressId, this.checkPermissions).subscribe(response => {
            this.addresses = this.addresses.filter(addr => addr.addressId !== response.data.addressId);
            this.addresses.unshift(response.data);

            // Notify list update event
            this.update.emit(this.addresses);

            this.value = response.data;
            this.inputCtrl.setValue(this.value);
          });
        }
      });
  }

  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.addresses = [];
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();

    this.initDefaultOptions();
  }

  selectFirst() {
    if (this.addresses.length === 1 && this.reSelectFirst) {
      this.value = this.addresses[0];
      this.inputCtrl.setValue(this.value);
      this.formChangedDirective?.setOldValue();

    } else if (!this.value) {
      this.value = null;
      this.inputCtrl.setValue(this.value);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.input.nativeElement?.blur();
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;

  registerOnTouched = (fn: any) => this.onTouched = fn;

  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;

  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: Address) {
    this.value = value;
    this.inputCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  updateAddresses() {
    this.initDefaultOptions();
  }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.addressService.search({ filter: { customerId: this.customer.customerId, enabled: true } },
        !!this.checkPermissions).toPromise()
        .then((response) => {
          this.addresses = response.data.results;
          this.selectFirst();
          this.cdr.detectChanges();
        })
        .finally(() => resolve());
    });
  }
}
