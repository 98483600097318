import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MailService } from '@core/api';
import { Breadcrumb, PpBreadcrumbsResolver } from 'pp-breadcrumbs';

@Injectable({
  providedIn: 'root'
})
export class AppBreadcrumbResolverService extends PpBreadcrumbsResolver {
  translations: any;

  constructor(private translate: TranslateService, private mailService: MailService) {
    super();
    translate.get(['MENU']).subscribe(translations => {
      this.translations = { ...translations.MENU };
    });
  }
  private getBreadcrumbTextByPath(path: string): string {
    switch (path) {
      case 'note': return this.translations?.NOTES;
      case 'activities': return this.translations?.ACTIVITIES;
      case 'leads': return this.translations?.CUSTOMERS;
      case 'opportunities': return this.translations?.OPPORTUNITIES;
      case 'offers': return this.translations?.OFFERS;
      case 'contacts': return this.translations?.CONTACTS;
      case 'reports': return this.translations?.REPORTS;
      case 'management': return this.translations?.MANAGEMENT_PANEL;
      case 'assignment': return this.translations?.TASKS;
      case 'mail': return this.translations?.MAIL;
      case 'notification': return this.translations?.NOTIFICATIONS;
      case 'calendar': return this.translations?.CALENDAR;
      case 'board': return this.translations?.BOARD;
      case 'profile': return this.translations?.PROFILE;
      case 'video-conference': return this.translations?.VIDEO_CONFERENCE;
      case 'leads-drafts': return this.translations?.LEADS;
      case 'activity-trend': return this.translations?.SALES_ACTIVITY_REPORT;
      case 'opportunity-report': return this.translations?.OPPORTUNITY_REPORT;
      case 'opportunity-offer-report': return this.translations?.OPPORTUNITY_OFFER_REPORT;
      case 'gain-report': return this.translations?.GAIN_REPORT;
      case 'leads-drafts-report': return this.translations?.LEAD_DRAFT_REPORT;
      case 'offers-report': return this.translations?.OFFER_ANALYSIS;
      case 'shipment-profile-report': return this.translations?.SHIPMENT_PROFILE_REPORT;
      case 'hashtag-report': return this.translations?.HASHTAG_REPORT;
      case 'user-guide': return this.translations?.USER_GUIDE;
      case 'language-management': return this.translations?.LANGUAGE_MANAGEMENT;
      case 'custom-dashboard': return this.translations?.CUSTOM_DASHBOARDS;
      case 'custom-report-table': return this.translations?.CUSTOM_DASHBOARDS;
      case 'nps-report': return this.translations?.NPS_REPORT;
      case 'customer-experience': return this.translations?.CX;
      case 'custom-list': return this.translations?.CUSTOM_LISTS;
      case 'smart-query': return this.translations?.SMART_QUERY;
    }
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Breadcrumb[]> {
    return new Promise((resolve) => {
      const url = route.url;

      if (!url[0]) {
        resolve([{ path: '', text: this.translations?.DASHBOARD }]);
        return;
      }

      // Extract root path from url
      const rootPath = url[0].path;

      // Initiate breadcrumbs
      const breadcrumbs = [
        {
          path: rootPath,
          text: this.getBreadcrumbTextByPath(rootPath),
        },
      ];

      // Add mail account breadcrumb
      if ('mail' === rootPath) {
        this.mailService.loginInfo$
          .subscribe((info) => {
            if (null === info) {
              resolve(breadcrumbs);
              return;
            }

            if (breadcrumbs.find(breadcrumb => breadcrumb.text === info.email)) {
              resolve(breadcrumbs);
              return;
            }

            breadcrumbs.push({
              path: '#',
              text: info.email,
            });
            resolve(breadcrumbs);
          });

        return;
      }

      resolve(breadcrumbs);
    });
  }
}
