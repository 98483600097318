import { AfterViewInit, Component, DestroyRef, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Router, RouterOutlet, Scroll } from '@angular/router';
import { VexConfigService } from '@vex/config/vex-config.service';
import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { MatSidenav, MatSidenavContainer, MatSidenavModule } from '@angular/material/sidenav';
import { VexColorScheme, VexConfig, VexConfigName } from '@vex/config/vex-config.interface';
import { LayoutService, StyleService } from '@shared/services';
import { checkRouterChildsData } from '@shared/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserConfigService } from '@core/user-config/user-config.service';
import { UserConfigType, UserConfigValue } from '@core/user-config/user-config.service.enum';
import { Icon, Style } from '@shared/enums';
import { Permission } from '@core/auth';
import { Store } from '@ngrx/store';
import { getSystemSettingValue, getUser, getUserPermissions, hasUserPermission } from '@core/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDashboardManagementService, CustomerExperienceTicket, DynamicFieldService, HotkeyService,
  SystemSettingMailProviderType, SystemSettingService, SystemSettingType, User
} from '@core/api';
import { NavigationLoaderService } from '@core/navigation/navigation-loader.service';
import { MailPreviewModel } from '../components/mail-preview/mail-preview.component.model';
import { QuickActionComponent } from '@shared/components/quick-action/quick-action.component';
import { AuthService } from '@core/auth/auth.service';
import { MailPreviewComponentService } from '../components/mail-preview/mail-preview.component.service';
import { CustomerExperienceTicketPreviewService } from '../components/customer-experience-ticket-preview/customer-experience-ticket-preview.service';
import { CustomerExperienceTicketPreviewComponent } from '../components/customer-experience-ticket-preview/customer-experience-ticket-preview.component';
import { MailPreviewComponent } from '../components/mail-preview/mail-preview.component';
import { SearchComponent } from '../components/search/search.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';

@Component({
  selector: 'net-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    BaseLayoutComponent,
    NgIf,
    AsyncPipe,
    SidenavComponent,
    ToolbarComponent,
    MatDialogModule,
    MatSidenavModule,
    RouterOutlet,
    SearchComponent,
    ProgressBarComponent,
    QuickActionComponent,
    CustomerExperienceTicketPreviewComponent,
    MailPreviewComponent
  ],
  standalone: true
})
export class LayoutComponent implements OnInit, AfterViewInit {
  config$: Observable<VexConfig> = this.configService.config$;
  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavDisableClose$: Observable<boolean> = this.layoutService.isDesktop$;
  sidenavFixedInViewport$: Observable<boolean> =
    this.layoutService.isDesktop$.pipe(map((isDesktop) => !isDesktop));

  isLayoutVertical$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isBoxed$ = this.configService.config$.pipe(map(config => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(map(config => config.toolbar.fixed));
  isFooterFixed$ = this.configService.config$.pipe(map(config => config.footer.fixed));
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;

  isDesktop$ = this.layoutService.isDesktop$;

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.scrollDisabled ?? false
      )
    )
  );

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null as string),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  containerEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null as string),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.containerEnabled))
  );

  userGuideControl: boolean;
  notificationCount = 0;
  user: User;

  navigationItems = [];

  isFinanceUser = false;
  isAccountingUser = false;
  isSalesDevelopment = false;
  isCustomDashboardGetUrl = false;
  isAccessAll = false;
  isCustomDashboardList = false;
  isCustomListSearch = false;
  isCustomListGet = false;
  isNoMailProvider = false;
  leadlessUsage: boolean;
  translations: any;
  customDashboards = [];
  customDashboardsDropdown: any;


  mailPreviews: MailPreviewModel[] = [];
  ticketPreviews: CustomerExperienceTicket[] = [];

  customLists = [];
  customListsDropdown: any;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;

  private readonly translate = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly systemSettingService = inject(SystemSettingService);
  private readonly store = inject(Store);
  private readonly navigationLoaderService = inject(NavigationLoaderService);
  private readonly customDashboardManagementService = inject(CustomDashboardManagementService);
  private readonly dynamicFieldService = inject(DynamicFieldService);

  constructor(
    private readonly layoutService: LayoutService,
    private readonly configService: VexConfigService,
    private styleService: StyleService,
    private router: Router,
    private hotkey: HotkeyService,
    private auth: AuthService,
    private userConfig: UserConfigService,
    private mailPreviewService: MailPreviewComponentService,
    private customerExperienceTicketPreviewService: CustomerExperienceTicketPreviewService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.store.select(getUserPermissions).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(permissions => {
      this.isFinanceUser = permissions?.some(permission => permission.permissionCode === Permission.FINANCIAL_INQUIRY_UPDATE);
      this.isAccountingUser = permissions?.some(permission => permission.permissionCode === Permission.ACCOUNT_PRODUCTION_ACCOUNT_CODE);
      this.isSalesDevelopment = permissions?.some(permission => permission.permissionCode === Permission.CONTRACT_UPDATE);
      this.isCustomDashboardList = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_DASHBOARD_LIST);
      this.isCustomDashboardGetUrl = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_DASHBOARD_GET_URL);
      this.isAccessAll = permissions?.some(permission => permission.permissionCode === Permission.ACCESS_ALL);
      this.isCustomListSearch = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_LIST_SEARCH);
      this.isCustomListGet = permissions?.some(permission => permission.permissionCode === Permission.CUSTOM_LIST_GET);
    });

    combineLatest([
      this.store.select(hasUserPermission(Permission.USER_GUIDE_INFO_SEARCH)).pipe(takeUntilDestroyed(this.destroyRef)),
      this.store.select(getSystemSettingValue(SystemSettingType.USER_GUIDE)).pipe(takeUntilDestroyed(this.destroyRef))
    ]).subscribe(response => {
      this.userGuideControl = response.every(Boolean);
    });

    // Check leadless switch
    this.store.select(getSystemSettingValue(SystemSettingType.ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT)).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => this.leadlessUsage = response as boolean);

    // Check no mail provider system setting flag is enabled
    this.store.select(getSystemSettingValue(SystemSettingType.MAIL_PROVIDERS)).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: string[]) => {
        const noProvider = value.find(v => v === SystemSettingMailProviderType.NO_PROVIDER);
        this.isNoMailProvider = noProvider !== undefined;
      });

    combineLatest([
      this.translate.get(['MENU', 'GENERAL']),
      this.store.select(getUser).pipe(takeUntilDestroyed(this.destroyRef))
    ]).subscribe(async ([translations, user]) => {
      this.user = user;
      this.translations = { ...translations.MENU, ...translations.GENERAL };

      if (user && translations) {

        if ((this.isCustomListSearch && this.isCustomListGet) || this.isAccessAll) {
          await this.initCustomLists();
        }

        if ((this.isCustomDashboardGetUrl && this.isCustomDashboardList) || this.isAccessAll) {
          await this.initCustomDashboards();
        }

        this.setNavigationItems();
        this.initMenu();
      }

    });

    this.configService.updateConfig({
      footer: {
        visible: false
      }
    });

    // close sidenav on routing
    this.router.events.subscribe(() => {
      this.layoutService.closeOpportunityCommunication();
      this.layoutService.closeAccountCommunication();
      this.layoutService.closeCustomReportsCommunication();
    });


    // Subscribe to mail link previews
    this.mailPreviewService.previews$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((previews) => this.mailPreviews = previews);

    // Subscribe to ticket chat previews
    this.customerExperienceTicketPreviewService.previews$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(previews => this.ticketPreviews = previews);

    // Add main layout hotkeys
    this.hotkey.add('h', this.translations?.SHOW_KEYBOARD_SHORTCUTS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.hotkey.openDialog());

    this.hotkey.add('control.shift.l', this.translations?.SIGN_OUT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.auth.logout());

    this.hotkey.add('control.d', this.translations?.VIEW_SUMMARY_SCREEN)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.router.navigate(['/']));

    this.hotkey.add('control.l', this.translations?.LIST_LEADS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.router.navigate(['/leads']));

    this.hotkey.add('control.f', this.translations?.OPENS_THE_SEARCH_SCREEN)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.layoutService.openSearch());

  }

  ngOnInit() {
    this.isDesktop$.pipe(
      filter(matches => !matches),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.layoutService.expandSidenav());

    this.layoutService.sidenavOpen$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(open => open ? this.sidenav?.open() : this.sidenav?.close());

    this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      withLatestFrom(this.isDesktop$),
      filter(([event, matches]) => !matches),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.sidenav?.close());

    // Set sidenav user config
    switch (this.userConfig.get(UserConfigType.SIDENAV)) {
      case UserConfigValue.SIDENAV_EXPANDED:
        this.layoutService.expandSidenav();
        break;

      case UserConfigValue.SIDENAV_COLLAPSED:
        this.layoutService.collapseSidenav();
        break;
    }

    // Set style user config
    switch (this.userConfig.get(UserConfigType.STYLE)) {
      case UserConfigValue.STYLE_DEFAULT:
        this.styleService.setStyle(Style.default);
        this.disableDefaultMode();
        break;

      case UserConfigValue.STYLE_LIGHT:
        this.styleService.setStyle(Style.light);
        this.enableLightMode();
        break;

      case UserConfigValue.STYLE_DARK:
        this.styleService.setStyle(Style.dark);
        this.enableDarkMode();
        break;
    }
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(
      filter<Scroll>(e => e instanceof Scroll),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.sidenavContainer?.scrollable.scrollTo({
          start: e.position[0],
          top: e.position[1]
        });
      } else if (e.anchor) {
        // anchor navigation

        const scroll = (anchor: HTMLElement) => this.sidenavContainer?.scrollable.scrollTo({
          behavior: 'smooth',
          top: anchor.offsetTop,
          left: anchor.offsetLeft
        });

        let anchorElem = this.document.getElementById(e.anchor);

        if (anchorElem) {
          scroll(anchorElem);
        } else {
          setTimeout(() => {
            anchorElem = this.document.getElementById(e.anchor);
            scroll(anchorElem);
          }, 100);
        }
      } else {
        // forward navigation
        if (e.routerEvent.url.includes('video-conference/')) {
          if (e.routerEvent.url.includes('new') || e.routerEvent.url.includes('opportunities')) {
            setTimeout(() => {
              this.sidenavContainer?.scrollable.scrollTo({
                bottom: 0
              });
            }, 100);
          }
        } else {
          this.sidenavContainer?.scrollable.scrollTo({
            top: 0,
            start: 0
          });
        }
      }
    });
  }


  initMenu() {
    const items = [];
    // Loop over navigation items and push to navigation services
    this.navigationItems.forEach((item) => {
      if (item === undefined) {
        return;
      }

      // Temporarily hide contact menu
      if (item.permission?.includes(Permission.CONTACT_LIST)) {
        return;
      }

      // Hide mail menu when no provider selected
      if (item.label === this.translate.instant('MENU.MAIL') && true === this.isNoMailProvider) {
        return;
      }

      // Hide lead draft field when leadless usage is active
      if (item.route === '/leads-drafts' && this.leadlessUsage) {
        return;
      }

      if (item.route === '/user-guide' && !this.userGuideControl) {
        return;
      }

      const tempItem: any = item;
      // Prepare nav item object
      const navItem: any = {
        type: tempItem.children ? 'dropdown' : 'link',
        label: tempItem.label,
        route: tempItem.route,
        icon: tempItem.icon,
        routerLinkActiveOptions: tempItem.routerLinkActiveOptions ? tempItem.routerLinkActiveOptions : { exact: false },
        children: [],
        badge: tempItem.badge,
      };

      // Add child menu if exists
      if (item.children) {
        item.children.forEach((child) => {
          this.store.select(hasUserPermission(child.permission)).pipe(
            takeUntilDestroyed(this.destroyRef)
          ).subscribe(resultChild => {
            if (resultChild) {
              navItem.children.push(child);
            }
          });
        });
      }

      // If menu needs permission definition check via auth service otherwise add directly
      if (item.permission) {
        this.store.select(hasUserPermission(item.permission)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
          if (result) {
            items.push(navItem);
          }
        });
        return;
      }

      items.push(navItem);
    });

    // if ((this.isFinanceUser || this.isAccountingUser) && !this.isSalesDevelopment) {
    //   items.shift();
    // }

    // Update navigation items
    this.navigationLoaderService.loadNavigation(items);
  }

  async initCustomDashboards() {
    await this.customDashboardManagementService.list(this.user?.userId).toPromise().then(items => {
      if (this.customDashboards.length < 1) {
        items?.data?.map(item => {
          this.customDashboards.push({
            type: 'link',
            permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
            label: item.name,
            route: '/custom-dashboard/' + item.customDashboardId + '?' + item.name,
          });
        });
        if (this.customDashboards.length > 0) {
          this.customDashboardsDropdown = {
            type: 'link',
            label: this.translate.instant('MENU.CUSTOM_DASHBOARDS'),
            icon: Icon.IC_CUSTOM_DASHBOARD,
            permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
            route: '/custom-report-table',
          };
        }
      }
    });
  }

  async initCustomLists() {
    if (this.customLists.length > 0) {
      return;
    }
    await this.dynamicFieldService.customList.list().toPromise().then(items => {
      this.customLists = items?.data?.map(item => ({
        type: 'link',
        permission: [Permission.CUSTOM_LIST_GET],
        label: this.translate.instant('CustomListDynamicField.' + item.name),
        route: '/custom-list/' + item.customListId,
      }));

      if (this.customLists.length > 0) {
        this.customListsDropdown = {
          type: 'link',
          label: this.translate.instant('MENU.CUSTOM_LISTS'),
          icon: Icon.IC_ROUND_TABLE_CHART,
          permission: [Permission.CUSTOM_LIST_SEARCH, Permission.CUSTOM_LIST_GET],
          children: [...this.customLists]
        };
      }
    });
  }

  onSidenavClosed(): void {
    this.layoutService.closeSidenav();
  }

  private setNavigationItems() {
    this.navigationItems = [
      {
        type: 'link',
        label: this.translate.instant('MENU.DASHBOARD'),
        route: '/',
        icon: Icon.IC_DASHBOARD,
        routerLinkActiveOptions: { exact: true },
        permission: [Permission.HOME_DASHBOARD]
      },
      {

        label: this.translate.instant('MENU.LEADS'),
        icon: Icon.IC_FILE_COPY,
        route: '/leads-drafts',
        permission: [Permission.LEAD_DRAFT_LIST]
      },
      {

        label: this.translate.instant('MENU.CUSTOMERS'),
        icon: Icon.IC_LEADS,
        route: '/leads',
        permission: [Permission.ACCOUNT_LIST]
      },
      {

        label: this.translate.instant('MENU.ACTIVITIES'),
        icon: Icon.IC_DATE_RANGE,
        route: '/activities',
        permission: [Permission.ACTIVITY_LIST]
      },
      {

        label: this.translate.instant('MENU.OPPORTUNITIES'),
        icon: Icon.IC_TWOTONE_BUSINESS_CENTER,
        route: '/opportunities',
        permission: [Permission.OPPORTUNITY_LIST]
      },
      {

        label: this.translate.instant('MENU.OFFERS'),
        icon: Icon.IC_ATTACH_FILE,
        route: '/offers',
        permission: [Permission.OFFER_LIST]
      },
      {

        label: this.translate.instant('MENU.CONTACTS'),
        icon: Icon.IC_PERSON_PIN,
        route: '/contacts',
        permission: [Permission.MENU_CONTACT]
      },
      {
        label: this.translate.instant('MENU.NPS_REPORT'),
        icon: Icon.RI_SURVEY_LINE,
        route: '/nps-report',
        permission: [Permission.MENU_NPS_REPORT]
      },
      {
        icon: Icon.IC_ASSIGNMENT,
        label: this.translate.instant('MENU.TASKS'),
        route: '/assignment',
        permission: [Permission.ASSIGNMENT_LIST],
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
      },
      {
        label: this.translate.instant('MENU.MAIL'),
        icon: Icon.MDI_EMAIL,
        route: '/mail',
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
        permission: [Permission.MAIL_MAIN_MENU]
      },
      {
        label: this.translate.instant('MENU.BOARD'),
        icon: Icon.UIL_MEETING_BOARD,
        route: '/board',
        permission: [Permission.BOARD_ADMIN, Permission.BOARD_SEARCH],
      },
      {

        label: this.translate.instant('MENU.CX'),
        icon: Icon.FLU_PERSON,
        route: '/customer-experience',
        permission: [Permission.CUSTOMER_EXPERIENCE_MENU],
      },
      {
        label: this.translate.instant('MENU.SMART_QUERY'),
        icon: Icon.STREAMLINE_HELP_CHAT_TWO_SOLID,
        route: '/smart-query',
        permission: [Permission.SMART_QUERY_SEARCH]
      },
      {
        icon: Icon.IC_NOTIFICATIONS_ACTIVE,
        label: this.translate.instant('MENU.NOTIFICATIONS'),
        route: '/notification',
        badge: {
          value: '',
          background: '#ff4946',
          color: '#ffeeee'
        },
        notification: 'bildirimler'
      },
      {
        label: this.translate.instant('MENU.VIDEO_CONFERENCE'),
        icon: Icon.MDI_VIDEO_BOX,
        route: '/video-conference',
        permission: [Permission.SALES_CALL_MONITOR]
      },
      {
        label: this.translate.instant('MENU.CALENDAR'),
        icon: Icon.IC_CALENDAR_TODAY,
        route: '/calendar',
        permission: [Permission.TEMP_CALENDAR],
      },
      this.customListsDropdown,
      this.customDashboardsDropdown,
      {
        type: 'dropdown',
        label: this.translate.instant('MENU.REPORTS'),
        icon: Icon.IC_ASSESSMENT,
        permission: [Permission.REPORT_LIST],
        children: [
          {
            type: 'link',
            label: this.translate.instant('MENU.SALES_ACTIVITY_REPORT'),
            route: '/reports/activity-trend',
            section: 'reports',
            permission: [Permission.REPORT_SALES_ACTIVITY]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OPPORTUNITY_REPORT'),
            route: '/reports/opportunity-report',
            section: 'reports',
            permission: [Permission.REPORT_OPPORTUNITY]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OPPORTUNITY_OFFER_REPORT'),
            route: '/reports/opportunity-offer-report',
            section: 'reports',
            permission: [Permission.REPORT_OPPORTUNITY_OFFER]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.GAIN_REPORT'),
            route: '/reports/gain-report',
            section: 'reports',
            permission: [Permission.GAIN_REPORT]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.LEAD_DRAFT_REPORT'),
            route: '/reports/leads-drafts-report',
            section: 'reports',
            permission: [Permission.REPORT_LEADS_DRAFTS]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.OFFER_ANALYSIS'),
            route: '/reports/offers-report',
            section: 'reports',
            permission: [Permission.OFFERS_REPORT]
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.SHIPMENT_PROFILE_REPORT'),
            route: '/reports/shipment-profile-report',
            section: 'reports',
            permission: [Permission.SHIPMENT_PROFILE_REPORT]
          },
          // {
          //   type: 'link',
          //   label: this.translate.instant('MENU.HASHTAG_REPORT'),
          //   route: '/reports/hashtag-report',
          //   permission: [Permission.HASHTAG_REPORT]
          // }
        ],
      },
      {
        label: this.translate.instant('MENU.USER_GUIDE'),
        icon: Icon.IC_HELP,
        route: '/user-guide'
      },
      {
        type: 'dropdown',
        label: this.translate.instant('MENU.MANAGEMENT_PANEL'),
        icon: Icon.IC_SETTINGS,
        permission: this.getManagementPanelPermissions(),
        children: [
          {
            type: 'link',
            label: 'User Guide',
            route: '/management/user-guide',
            permission: [Permission.USER_GUIDE_LIST],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.USER_LIST'),
            route: '/management/users',
            permission: [Permission.MENU_USER_LIST],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.CURRENCY_MANAGEMENT'),
            route: '/management/currency-management',
            permission: [Permission.TRANSACTION_CURRENCY_MENU],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.ROLE_MANAGEMENT'),
            route: '/management/role-management',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: 'Sales Organization',
            route: '/management/sales-organization',
            permission: [Permission.SHOW_OPPORTUNITY_STATUS_PROCESS, Permission.MENU_SALES_ORGANIZATION],
          },
          {
            type: 'link',
            label: 'Service Management',
            route: '/management/service-management',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: 'TAX Office',
            route: '/management/tax-office',
            permission: [Permission.ACCESS_ALL],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.ACCOUNT_MERGE'),
            route: '/management/account-merge',
            permission: [Permission.ACCOUNT_MERGE_INSERT],
          },
          {
            type: 'link',
            label: 'Dynamic Field Management',
            route: '/management/dynamic-field-management',
            permission: [Permission.DYNAMIC_FIELD_INSERT, Permission.DYNAMIC_FIELD_UPDATE],
          }, {
            type: 'link',
            label: this.translate.instant('MENU.DATA_EXPORTS'),
            route: '/management/data-exports',
            permission: [Permission.LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.DASHBOARD_MANAGEMENT'),
            route: 'management/custom-dashboard-management',
            permission: Permission.CUSTOM_DASHBOARD_SEARCH,
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.SURVEY'),
            route: 'management/survey-management',
            permission: [Permission.MENU_SURVEY_MANAGEMENT],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.CX_SETTINGS'),
            route: '/management/customer-experience-management',
            permission: this.getCxSettingsPermissions()
          },
          {
            type: 'link',
            label: 'General Settings',
            route: '/management/system-setting-management',
            permission: [Permission.REDIS_FLUSH_DATABASES],
          },
          {
            type: 'link',
            label: this.translate.instant('MENU.LANGUAGE_MANAGEMENT'),
            route: '/management/language-management',
            permission: [Permission.LANGUAGE_MANAGEMENT],
          },
        ],
      },
    ];
  }

  private getManagementPanelPermissions(): Permission[] {
    return [
      Permission.ACCOUNT_MERGE_INSERT, Permission.USER_GUIDE_LIST, Permission.DYNAMIC_FIELD_MANAGEMENT_ACTIVITY_PURPOSE,
      Permission.DYNAMIC_FIELD_MANAGEMENT_CUSTOMER_TYPE, Permission.TRANSACTION_CURRENCY_MENU, Permission.SHOW_OPPORTUNITY_STATUS_PROCESS,
      Permission.LANGUAGE_MANAGEMENT, Permission.DYNAMIC_FIELD_INSERT, Permission.DYNAMIC_FIELD_UPDATE, Permission.CUSTOM_DASHBOARD_SEARCH,
      Permission.MENU_SURVEY_MANAGEMENT, Permission.LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH, Permission.CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU, Permission.CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU, Permission.CUSTOMER_EXPERIENCE_SLA_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU, Permission.CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU, Permission.CUSTOMER_EXPERIENCE_CHARTS_MENU,
      Permission.MENU_SALES_ORGANIZATION, Permission.MENU_USER_LIST, Permission.CUSTOMER_EXPERIENCE_UNWANTED_PHRASES_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUESTION_ANSWER_MENU
    ];
  }

  private getCxSettingsPermissions(): Permission[] {
    return [
      Permission.CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU,
      Permission.CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU,
      Permission.CUSTOMER_EXPERIENCE_SLA_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU,
      Permission.CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU,
      Permission.CUSTOMER_EXPERIENCE_CHARTS_MENU,
      Permission.CUSTOMER_EXPERIENCE_UNWANTED_PHRASES_MENU,
      Permission.CUSTOMER_EXPERIENCE_QUESTION_ANSWER_MENU
    ];
  }

  private enableLightMode(): void {
    this.configService.setConfig(VexConfigName.ares);
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  private enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.DARK
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

  private disableDefaultMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT
      },
      sidenav: {
        state: this.userConfig.get(UserConfigType.SIDENAV)
      }
    });
  }

}
