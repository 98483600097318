import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActiveUser,
  FilterRequest, GetUserHierarchyRequest, PagedResponse, ProfileLanguageNotificationUpdateRequest, Response, SystemUser,
  SystemUserFilterRequest, SystemUserRequest, SystemUserUpdateStatusRequest, SystemUserWithSalesOrganization, TeamMember
} from '@core/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemUserService {

  constructor(private http: HttpClient) { }

  list(filter?: FilterRequest): Observable<PagedResponse<SystemUser>> {
    const request = filter || {};

    if (!request.pageSize) {
      request.pageSize = 100;
    }

    return this.http.post<PagedResponse<SystemUser>>(environment.apiUrl + '/api/SystemUser/List', request);
  }

  stats(): Observable<Response<ActiveUser>> {
    return this.http.post<Response<ActiveUser>>(environment.apiUrl + '/api/SystemUser/ActiveStats', {});
  }

  updateConfig(userId: string, config: string) {
    const request = {
      systemUserId: userId,
      property: config
    };

    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserProperty/Update', request);
  }

  updateTableFilterConfigs(userId: string, filter: string) {
    const request = {
      systemUserId: userId,
      tableFilter: filter
    };

    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserProperty/UpdateTableFilter', request);
  }

  updateReportFilterConfigs(userId: string, filter: string) {
    const request = {
      systemUserId: userId,
      reportFilter: filter
    };

    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserProperty/UpdateReportFilter', request);
  }

  updateRecentPageConfigs(userId: string, recentPage: string) {
    const request = {
      systemUserId: userId,
      recentPage
    };
    return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemUserProperty/UpdateRecentPage', request);
  }

  get(systemUserId: string): Observable<Response<SystemUser>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/Get', JSON.stringify(systemUserId), { headers });
  }

  search(filter?: FilterRequest): Observable<PagedResponse<SystemUser>> {
    return this.http.post<PagedResponse<SystemUser>>(environment.apiUrl + '/api/SystemUser/Search', filter);
  }

  update(request: SystemUserRequest): Observable<Response<SystemUser>> {
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/Update', request);
  }

  getUserRoles(systemUserId: string): Observable<Response<SystemUser>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/GetUserRoles', JSON.stringify(systemUserId), { headers });
  }

  insert(request: SystemUserRequest): Observable<Response<SystemUser>> {
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/Insert', request);
  }

  userSearch(filter?: FilterRequest): Observable<PagedResponse<SystemUser>> {
    return this.http.post<PagedResponse<SystemUser>>(environment.apiUrl + '/api/SystemUser/UserSearch', filter);
  }

  changeStatus(request: SystemUserUpdateStatusRequest): Observable<Response<SystemUser>> {
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/ChangeStatus', request);
  }

  profileUpdate(request: SystemUserRequest) {
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/ProfileUpdate', request);
  }

  getProfile(id: string): Observable<Response<SystemUser>> {
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<Response<SystemUser>>(environment.apiUrl + '/api/SystemUser/GetProfile', JSON.stringify(id), { headers });
  }

  profileSearch(filter?: SystemUserFilterRequest): Observable<PagedResponse<TeamMember>> {
    return this.http.post<PagedResponse<TeamMember>>(environment.apiUrl + '/api/SystemUser/ProfileSearch', filter);
  }

  profileLanguageNotificationUpdate(filter?: ProfileLanguageNotificationUpdateRequest): Observable<PagedResponse<TeamMember>> {
    return this.http.post<PagedResponse<TeamMember>>(environment.apiUrl + '/api/SystemUser/ProfileLanguageNotificationUpdate', filter);
  }

  getUserHierarchy(request: GetUserHierarchyRequest): Observable<Response<SystemUser[]>> {
    return this.http.post<Response<SystemUser[]>>(environment.apiUrl + '/api/SystemUser/GetUserHierarchy', request);
  }

  getSalesOrganizationWithUsers(request: { salesOrganizationId: string; systemUserIds: string[] }): Observable<Response<SystemUserWithSalesOrganization>> {
    return this.http.post<Response<SystemUserWithSalesOrganization>>(environment.apiUrl + '/api/SystemUser/GetSalesOrganizationWithUsers', request);
  }
}
