export enum SmartQueryThreadActionType {
    ARTIFICAIL_INTELLIGENCE = 'ai',
    GET_THREADS = 'GetThreads',
    CREATE_THREAD = 'CreateThread',
    ASKING_TASK = 'AskingTask',
    CREATE_ASKING_TASK = 'CreateAskingTask',
    DELETE_THREAD = 'DeleteThread',
    UPDATE_THREAD_NAME = 'UpdateThreadName',
    PREVIEW_DATA = 'PreviewData',
    RUN_THREAD = 'RunThread',
    GET_THREAD = 'GetThread',
    NEW_QUESTION = 'NewQuestion',
    NEW_THREAD = 'NewThread',
}

export enum SmartQueryResponseColumn {
    SYSTEM_USER_ID = 'SystemUserId',
    CREATED_BY = 'CreatedBy',
    CREATED_ON = 'CreatedOn'
}
